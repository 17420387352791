import React, {useState} from "react";
import SelectWithCustom from "../../../textselector/SelectWithCustom";

const headlineFontStyles = [
    {key: "None", value: "none"},
    {key: "Bold", value: "bold"},
    {key: "Underline", value: "underline"},
    {key: "Custom", value: "custom"}
];

function HeadlineFontStyle({panel, placementId, panelPosition, widgetProps, setWidgetProps}) {
    const isCustom = panel.isCustomFontStyle;
    const [customValue, setCustomValue] = useState(isCustom ? panel.headlineFontStyle : '');

    function handleSelect(value) {
        const props = {};
        setCustomValue("");

        if(value !== 'custom') {
            props.headlineFontStyle = value;
            props.isCustomFontStyle = false;
        } else {
            props.headlineFontStyle = "";
            props.isCustomFontStyle = true;
        }

        setHeadlineStyleProperties(props);
    }

    function handleCustom() {
        const props = {};
        props.headlineFontStyle = customValue;
        props.isCustomFontStyle = true;
        setHeadlineStyleProperties(props);
    }

    function setHeadlineStyleProperties(props) {
        const wProps = {...widgetProps};
        let newPlacements = [];
        let newPanels = [];

        widgetProps.placements.map(placement => {
            let newPlacement = placement;
            if(placement.id === placementId) {
                placement.panels.map(panel => {
                    let newPanel = panel;
                    if(panel.position === panelPosition) {
                        newPanel.headlineFontStyle = props.headlineFontStyle;
                        newPanel.isCustomFontStyle = props.isCustomFontStyle;
                    }
                    newPanels.push(newPanel);

                    return panel;
                })
                newPlacement.panels = newPanels;
            }
            newPlacements.push(newPlacement);

            return placement;
        })

        wProps.placements = newPlacements;
        setWidgetProps(wProps);
    }

    const hlFontStyleProps = {};
    hlFontStyleProps.labelName = "Font-Style";
    hlFontStyleProps.selectValue = panel.headlineFontStyle;
    hlFontStyleProps.mappedOptionPairs = headlineFontStyles;

    return(
        <>
            <div>
                <SelectWithCustom settings={hlFontStyleProps} isCustom={isCustom} customValue={customValue}
                                  setCustomValue={setCustomValue} handleSelect={handleSelect}
                                  handleCustom={handleCustom} customLabel="Custom Font Style" />
            </div>
        </>
    );
}

export default HeadlineFontStyle;
