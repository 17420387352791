import React, { useState } from "react";
import Settings from "./widget/Settings";
import { v4 as uuidv4 } from "uuid";
import PlacementTabs from "./PlacementTabs";

const initialPanel = {
  position: 1,
  optionalSettingsHidden: true,
  panelLayout: "1",
  offset: 0,
  internal: false,
  subIds: "",
  learnMoreButtonHidden: false,
  learnMoreButtonColor: "",
  providerHidden: false,
  providerColor: "",
  headlineColor: "",
  headlineSize: "17",
  headlineFont: "arial",
  isCustomFont: false,
  headlineFontStyle: "none",
  isCustomFontStyle: false
};

const initialPlacement = {
  id: 1,
  placementLayout: "1",
  maxWidth: "1300",
  layoutSettingsHidden: true,
  size: "300x250",
  isCustomSize: false,
  headlineSizeLocked: true,
  panels: [{ ...initialPanel }]
};

const initialWidget = {
  uuid: "%CAMPAIGNID%%CONTACTID%%LASTNAME%",
  isCustomKey: false,
  learnMoreButtonDefaultColor: "#fd7b10",
  providerDefaultColor: "#808080",
  headlineDefaultColor: "#000000",
  minHeadlineSize: "10",
  maxHeadlineSize: "36",
  defaultHeadlineSize: "24",
  defaultHeadlineFont: "arial",
  placements: [{ ...initialPlacement }]
};

export const fixedHeadlineSizes = {
  "300x250": "17",
  "250x250": "16",
  "240x400": "19",
  "300x600": "32",
  "970x250": "36",
  "970x550": "36",
  "400x100": "18",
  "460x150": "18",
  "570x150": "19"
};

function Widget() {
  let [widgetProps, setWidgetProps] = useState(initialWidget);
  const placements = widgetProps.placements;

  const MAX_PLACEMENTS = 3;

  function resetPlacementLayout(placementId, layoutValue) {
    const wProps = { ...widgetProps };
    const placements = wProps.placements;
    let nextSponsoredOffset = 0;
    let nextInternalOffset = 0;

    let newPlacements = [];
    placements.map((placement) => {
      let newPlacement = placement;

      if (placement.id < placementId) {
        placement.panels.map((panel) => {
          nextSponsoredOffset = panel.internal
            ? nextSponsoredOffset
            : nextSponsoredOffset + 1;
          nextInternalOffset = panel.internal
            ? nextInternalOffset + 1
            : nextInternalOffset;

          return panel;
        });
      } else if (placement.id === placementId) {
        let newPanel = { ...initialPanel };
        newPanel.offset = nextSponsoredOffset;
        nextSponsoredOffset++;
        newPanel.panelLayout = layoutValue === "custom" ? "1" : layoutValue;
        newPanel.learnMoreButtonColor = "";
        let newPanels = [];
        newPanels.push(newPanel);

        newPlacement = { ...initialPlacement };
        newPlacement.layoutSettingsHidden = placement.layoutSettingsHidden;
        newPlacement.panels = newPanels;
        newPlacement.id = placement.id;
        newPlacement.placementLayout = layoutValue;
      } else if (placement.id > placementId) {
        let newPanels = [];
        placement.panels.map((panel) => {
          let newPanel = panel;
          if (panel.internal) {
            newPanel.offset = nextInternalOffset;
            nextInternalOffset++;
          } else {
            newPanel.offset = nextSponsoredOffset;
            nextSponsoredOffset++;
          }
          newPanels.push(newPanel);

          return panel;
        });
        newPlacement.panels = newPanels;
      }
      newPlacements.push(newPlacement);

      return placement;
    });
    wProps.placements = newPlacements;

    setWidgetProps(wProps);
  }

  function getPanelTotals() {
    let panelTotals = {};
    let totalSponsored = 0;
    let totalInternal = 0;

    widgetProps.placements.map((placement) => {
      placement.panels.map((panel) => {
        totalSponsored = panel.internal ? totalSponsored : totalSponsored + 1;
        totalInternal = panel.internal ? totalInternal + 1 : totalInternal;
        return panel;
      });

      return placement;
    });

    panelTotals.totalSponsored = totalSponsored;
    panelTotals.totalInternal = totalInternal;
    panelTotals.grandTotal = totalSponsored + totalInternal;

    return panelTotals;
  }

  function handleAddPanel(addPlacementId) {
    let nextSponsoredOffset = 0;
    const wProps = { ...widgetProps };
    const placements = wProps.placements;
    const newPlacements = [];

    placements.map((placement) => {
      let newPlacement = placement;
      if (placement.id < addPlacementId) {
        placement.panels.map((panel) => {
          nextSponsoredOffset = panel.internal
            ? nextSponsoredOffset
            : nextSponsoredOffset + 1;
          return panel;
        });
      } else if (placement.id === addPlacementId) {
        const newPanels = placement.panels;

        placement.panels.map((panel) => {
          nextSponsoredOffset = panel.internal
            ? nextSponsoredOffset
            : nextSponsoredOffset + 1;
          return panel;
        });

        let newPanel = { ...initialPanel };
        newPanel.position = placement.panels.length + 1;
        newPanel.offset = nextSponsoredOffset;
        newPanels.push(newPanel);
        newPlacement.panels = newPanels;
      } else {
        if (placement.id > addPlacementId) {
          const newPanels = [];
          placement.panels.map((panel) => {
            let newPanel = panel;
            if (!panel.internal) {
              newPanel.offset++;
            }

            return newPanels.push(newPanel);
          });

          newPlacement.panels = newPanels;
        }
      }

      newPlacements.push(newPlacement);
      return placement;
    });

    wProps.placements = newPlacements;
    setWidgetProps(wProps);
  }

  function deletePanel(panels, panelPosition) {
    const resultArray = {};
    const newPanels = [];
    let removedPanelType = "";
    panels.map(function (panel) {
      if (panel.position === panelPosition) {
        removedPanelType = panel.internal ? "internal" : "sponsored";
      } else {
        if (panel.position > panelPosition) {
          panel.position--;
          let panelType = panel.internal ? "internal" : "sponsored";
          if (panelType === removedPanelType) {
            panel.offset = panel.offset - 1;
          }
        }
        newPanels.push(panel);
      }

      return panel;
    });

    resultArray.panels = newPanels;
    resultArray.removedAdIsInternal = removedPanelType === "internal";

    return resultArray;
  }

  function handleDeletePanel(removePlacementId, removePanelPosition) {
    const wProps = { ...widgetProps };
    const placements = wProps.placements;
    let sponsoredRemoved = 0;
    let internalRemoved = 0;
    let newPlacements = [];
    let newPlacement = {};
    placements.map((placement) => {
      if (placement.id === removePlacementId) {
        newPlacement = placement;
        const deleteResult = deletePanel(placement.panels, removePanelPosition);
        sponsoredRemoved = deleteResult.removedAdIsInternal
          ? sponsoredRemoved
          : sponsoredRemoved + 1;
        internalRemoved = deleteResult.removedAdIsInternal
          ? internalRemoved + 1
          : internalRemoved;

        newPlacement.panels = deleteResult.panels;
      } else {
        newPlacement = placement;
        let newPanels = [];
        if (placement.id > removePlacementId) {
          //dec offsets if same as delete type
          let panels = placement.panels;
          panels.map((panel) => {
            panel.offset = panel.internal
              ? panel.offset - internalRemoved
              : !panel.internal
              ? panel.offset - sponsoredRemoved
              : panel.offset;

            return newPanels.push(panel);
          });

          newPlacement.panels = newPanels;
        } else {
          newPlacement = placement;
        }
      }

      return newPlacements.push(newPlacement);
    });

    wProps.placements = newPlacements;
    setWidgetProps(wProps);
  }

  function handleAddPlacement() {
    const wProps = { ...widgetProps };

    const newPlacement = { ...initialPlacement };
    newPlacement.id = wProps.placements.length + 1;

    let nextSponsoredOffset = 0;
    wProps.placements.map((placement) => {
      placement.panels.map((panel) => {
        if (!panel.internal) nextSponsoredOffset++;
        return panel;
      });
      return placement;
    });

    const newPanel = { ...initialPanel };
    newPanel.offset = nextSponsoredOffset;

    newPlacement.panels = [newPanel];
    wProps.placements.push(newPlacement);

    setWidgetProps(wProps);
  }

  function handleDeletePlacement(removePlacementId) {
    const wProps = { ...widgetProps };
    const placements = wProps.placements;

    let nextSponsoredOffset = 0;
    let nextInternalOffset = 0;
    const newPlacements = [];
    let newPlacement = [];
    placements.map((placement) => {
      if (placement.id < removePlacementId) {
        newPlacements.push(placement);
        placement.panels.map((panel) => {
          nextSponsoredOffset = panel.internal
            ? nextSponsoredOffset
            : nextSponsoredOffset + 1;
          nextInternalOffset = panel.internal
            ? nextInternalOffset + 1
            : nextInternalOffset;

          return panel;
        });
      } else if (placement.id > removePlacementId) {
        newPlacement = placement;
        newPlacement.id = placement.id - 1;

        let newPanels = [];
        placement.panels.map((panel) => {
          let newAd = panel;
          if (panel.internal) {
            newAd.offset = nextInternalOffset;
            nextInternalOffset++;
          } else {
            newAd.offset = nextSponsoredOffset;
            nextSponsoredOffset++;
          }
          newPanels.push(newAd);

          return panel;
        });
        newPlacement.panels = newPanels;

        newPlacements.push(newPlacement);
      }

      return placement;
    });

    wProps.placements = newPlacements;
    setWidgetProps(wProps);
  }

  function handlePlacementUpdate(updatePlacementId, placementId) {
    const newPlacement = { ...updatePlacementId };
    const wProps = { ...widgetProps };
    const newPlacements = [];
    const placements = wProps.placements;
    placements.map(function (placement) {
      if (placement.id === placementId) {
        newPlacements.push(newPlacement);
      } else {
        newPlacements.push(placement);
      }

      return placement;
    });
    wProps.placements = newPlacements;
    setWidgetProps(wProps);
  }

  function AddPlacementButton({ showButton }) {
    return (
      <>
        <button
          className="add-component-button"
          style={showButton ? { display: "block" } : { display: "none" }}
          onClick={handleAddPlacement}
        >
          Add Placement
        </button>
      </>
    );
  }

  const showAddPlacementButton = placements.length < MAX_PLACEMENTS;
  const showDeletePlacementButton = placements.length > 1;
  widgetProps.panelTotals = getPanelTotals();
  widgetProps.internalKey = uuidv4();

  return (
    <>
      <div id="widget-body">
        <div className="component-container">
          <Settings widgetProps={widgetProps} setWidgetProps={setWidgetProps} />
          <div>
            <div className="flex-row-left" id="panels-header-flex-row">
              <div className="flex-column">
                <h4
                  className="component-header component-header-with-btn"
                  style={{ display: "none" }}
                >
                  PLACEMENTS
                </h4>
              </div>
              <div className="flex-column">
                <AddPlacementButton showButton={showAddPlacementButton} />
              </div>
            </div>
          </div>
          <PlacementTabs
            handleAddPanel={handleAddPanel}
            handleDeletePanel={handleDeletePanel}
            handlePlacementUpdate={handlePlacementUpdate}
            resetPlacementLayout={resetPlacementLayout}
            handleDeletePlacement={handleDeletePlacement}
            showDeletePlacementButton={showDeletePlacementButton}
            widgetProps={widgetProps}
            setWidgetProps={setWidgetProps}
          />
        </div>
      </div>
    </>
  );
}

export default Widget;
