import React from "react";

function WidgetId({widgetProps, setWidgetProps}) {
    function handleWidgetId(widgetId) {
        const props = {...widgetProps};
        props.widgetId = widgetId;
        return props;
    }

    return (
        <>
            <div className="flex-column">
                <label>Widget ID:</label>
            </div>
            <input type="text" id="widget-id-input" onChange={(event) => {
                setWidgetProps(handleWidgetId(event.target.value));
            }} />
        </>
    );
}

export default WidgetId;
