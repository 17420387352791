import React, {useState} from "react";
import {fixedHeadlineSizes} from "../../../Widget";
import {v4 as uuidv4} from "uuid";

const headlineSizes = [
    "240x400",
    "250x250",
    "300x250",
    "300x600",
    "336x280",
    "400x100",
    "400x150",
    "460x150",
    "570x150",
    "600x250",
    "600x300",
    "720x300",
    "970x250",
    "970x550",
    "custom"
];

function SizeOption() {
    return (
        <>
            {
                headlineSizes.map(headlineSize => (
                    <option key={uuidv4()} value={headlineSize}>{headlineSize === "custom" ? "Custom" : headlineSize}{(headlineSize in fixedHeadlineSizes) ? " (headline=" + fixedHeadlineSizes[headlineSize] + ")" : ""}</option>
                ))
            }
        </>
    );
}

function SelectSize({selectedSize, handleSize, setW, setH, setSizeErrorMsg}) {

    function handleSelectSize(imageSize) {
        setSizeErrorMsg("");
        const sizeProps = {};

        if(imageSize !== 'custom') {
            sizeProps.size = imageSize;
            sizeProps.isCustomSize = false;
            sizeProps.headlineSizeLocked = imageSize in fixedHeadlineSizes;

            setW("");
            setH("");
        } else {
            sizeProps.isCustomSize = true;
            sizeProps.headlineSizeLocked = false;
        }

        handleSize({sizeProps});
    }

    return(
        <>
            <label className="group-label">Image Size:</label>
            <select value={selectedSize} onChange={(event) => {
                handleSelectSize(event.target.value);
            }}>
                <SizeOption />
            </select>
        </>
    );
}

function CustomSize({placementComponent, handleSize, w, setW, h, setH, sizeErrorMsg, setSizeErrorMsg}) {
    const placement = {...placementComponent};
    const disableCustomSize = placement.isCustomSize ? "" : "disabled";
    const labelClass = placement.isCustomSize ? "label-black" : "label-lightgray";

    function handleWidth(event) {
        setSizeErrorMsg("");
        setW(event.target.value);
    }

    function handleHeight(event) {
        setSizeErrorMsg("");
        setH(event.target.value);
    }

    function handleApply() {
        const sizeProps = {};
        sizeProps.isCustomSize = true;

        if(validateSize()) {
            let customSize = w + "x" + h;
            sizeProps.size = customSize;
            sizeProps.headlineSizeLocked = customSize in fixedHeadlineSizes;
            handleSize({sizeProps});
        }
    }

    function validateSize() {
        const validWidth = validateDimension(w);
        const validHeight = validateDimension(h);

        setSizeErrorMsg(!validWidth && !validHeight
            ? "Invalid width and height"
            : !validWidth
                ? "Invalid width"
                : !validHeight
                    ? "Invalid height"
                    : ""
        )

        return validWidth && validHeight;
    }

    function validateDimension(dimension) {
        return !(dimension === "" || dimension === "0" || dimension.includes("."));
    }

    return(
        <>
            <label className={labelClass + " group-label"} >Custom Size:</label>
            <div>
                <label className={labelClass}>
                    width: <input className="size-input" type="text" disabled={disableCustomSize} value={w} onChange={handleWidth} />
                </label>
                <label className={labelClass}>
                    height: <input className="size-input" type="text" disabled={disableCustomSize} value={h} onChange={handleHeight} />
                </label>
                <button className="settings-btn" type="button" disabled={disableCustomSize} onClick={handleApply}>
                    Apply
                </button>
                <em style={{color: "red", fontSize: "15px"}}>&nbsp;<b>{sizeErrorMsg}</b></em>
            </div>
        </>
    );
}

function Size({placementComponent, handleSize}) {
    let sizeSplit = placementComponent.size.split("x");

    let currentWidth = sizeSplit[0];
    let currentHeight = sizeSplit[1];

    const [w, setW] = useState(placementComponent.isCustomSize ? currentWidth : '');
    const [h, setH] = useState(placementComponent.isCustomSize ? currentHeight : '');
    const [sizeErrorMsg, setSizeErrorMsg] = useState("");

    return(
        <>
            <div className="flex-row-left size-container">
                <div id="size-column-1" className="flex-column">
                    <SelectSize selectedSize={placementComponent.isCustomSize ? 'custom' : placementComponent.size } handleSize={handleSize} setW={setW} setH={setH} setSizeErrorMsg={setSizeErrorMsg} />
                </div>
                <div id="size-column-2" className="flex-column">
                    <CustomSize placementComponent={placementComponent} handleSize={handleSize} w={w} setW={setW} h={h} setH={setH} sizeErrorMsg={sizeErrorMsg} setSizeErrorMsg={setSizeErrorMsg} />
                </div>
            </div>
        </>
    );
}

export default Size;
