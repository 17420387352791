import React from "react";
import PlacementLayout from "./inputs/placement/PlacementLayout";
import Size from "./inputs/placement/Size";
import { Preview } from "./outputs/Preview";
import Markup from "./outputs/Markup";
import PanelTabs from "./PanelTabs";
import { fixedHeadlineSizes } from "../Widget";
import MaxWidth from "./inputs/settings/MaxWidth";

function Placement({
  placementComponent,
  handleAddPanel,
  handleDeletePanel,
  handlePlacementUpdate,
  showDeletePlacementButton,
  deletePlacement,
  resetPlacementLayout,
  widgetProps,
  setWidgetProps
}) {
  const placement = { ...placementComponent };

  const MAX_PANELS_PER_PLACEMENT = 9;
  const SPACE_PIXELS = 5;

  function toggleOptionalLayout() {
    placement.layoutSettingsHidden = !placement.layoutSettingsHidden;
    handlePlacementUpdate(placement, placement.id);
  }

  function getPanelsPerRow(placement) {
    let panelsPerRow;

    let parts = placement.size.split("x");
    let imageWidthWithMargin = Number(parts[0]) + SPACE_PIXELS;
    let panelsPerRowInitial = Math.floor(
      Number(placement.maxWidth) / (imageWidthWithMargin + SPACE_PIXELS)
    );
    panelsPerRowInitial = panelsPerRowInitial < 1 ? 1 : panelsPerRowInitial;

    switch (placement.panels.length) {
      case 1:
        panelsPerRow = 1;
        break;
      case 2:
        panelsPerRow = 2;
        break;
      case 3:
        panelsPerRow = 3;
        break;
      case 4:
        panelsPerRow = 4;
        break;
      case 5:
        panelsPerRow = 3;
        break;
      case 6:
        panelsPerRow = 3;
        break;
      case 7:
        panelsPerRow = 4;
        break;
      case 8:
        panelsPerRow = 4;
        break;
      default:
        break;
    }

    return panelsPerRow <= panelsPerRowInitial
      ? panelsPerRow
      : panelsPerRowInitial;
  }

  function getPanelsRows(placementId, panelsPerRow) {
    const wProps = { ...widgetProps };

    let row = [];
    let rows = [];
    wProps.placements.map((placement) => {
      if (placement.id === placementId) {
        placement.panels.map((panel) => {
          row.push(panel);
          if (row.length === panelsPerRow) {
            rows.push(row);
            row = [];
          }
          return panel;
        });
      }
      return placement;
    });
    if (row.length > 0) rows.push(row);

    return rows;
  }

  function handleLayout(layoutValue) {
    resetPlacementLayout(placementComponent.id, layoutValue);
  }

  function handleMaxWidth(maxWidth) {
    placement.maxWidth = maxWidth;
    handlePlacementUpdate(placement, placement.id);
  }

  function handleSize({ sizeProps }) {
    const props = { ...sizeProps };
    placement.size = props.size ? props.size : placement.size;
    placement.isCustomSize = props.isCustomSize;
    placement.headlineSizeLocked = props.headlineSizeLocked;
    if (props.headlineSizeLocked) {
      placement.panels.map((panel) => {
        panel.headlineSize = fixedHeadlineSizes[props.size];

        return panel;
      });
    }
    handlePlacementUpdate(placement, placement.id);
  }

  function AddPanelButton({ showButton }) {
    return (
      <>
        <button
          className="add-component-button"
          style={showButton ? { display: "block" } : { display: "none" }}
          onClick={() => handleAddPanel(placement.id)}
        >
          Add
        </button>
      </>
    );
  }

  function DeletePlacementButton() {
    return (
      <>
        <div className="flex-row-right">
          <div className="flex-column">Delete Placement</div>
          <div className="flex-column">
            <button
              className="container-header-del-btn"
              style={
                showDeletePlacementButton
                  ? { display: "block" }
                  : { display: "none" }
              }
              onClick={() => deletePlacement(placement.id)}
            >
              x
            </button>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <div className="row">
        <div className="column-left" style={{ marginLeft: "5px" }}>
          <div className="flex-row-left">
            <div className="flex-column">
              <h4 className="component-header">OPTIONAL LAYOUT</h4>
            </div>
            <div className="flex-column">
              <button className="hide-show-btn" onClick={toggleOptionalLayout}>
                <img className="hide-show-img" src={process.env.PUBLIC_URL + (placement.layoutSettingsHidden ? "/images/down_arrow.png" : "/images/up_arrow.png")} alt="Arrow"/>
              </button>
            </div>
          </div>
        </div>
        <div
          className="column-right"
          style={
            showDeletePlacementButton
              ? { display: "block" }
              : { display: "none" }
          }
        >
          <div style={{ float: "right" }}>
            <DeletePlacementButton />
          </div>
        </div>
      </div>
      <div
        className="optional-container"
        style={placement.layoutSettingsHidden ? { display: "none" } : {}}
      >
        <div className="flex-row-left">
          <div className="flex-column">
            <PlacementLayout
              handleLayout={handleLayout}
              placementLayoutValue={placement.placementLayout}
            />
          </div>
          <div
            className="flex-column"
            style={
              placement.placementLayout === "custom" ? {} : { display: "none" }
            }
          >
            <MaxWidth
              handleMaxWidth={handleMaxWidth}
              maxWidthValue={placement.maxWidth}
            />
          </div>
        </div>
        <Size placementComponent={placement} handleSize={handleSize} />
      </div>
      <div>
        <div className="flex-row-left" id="panels-header-flex-row">
          <div className="flex-column">
            <h4 className="component-header component-header-with-btn"> </h4>
          </div>
          <div className="flex-column">
            <AddPanelButton
              showButton={
                placement.panels.length < MAX_PANELS_PER_PLACEMENT &&
                placement.placementLayout === "custom"
              }
            />
          </div>
        </div>
      </div>
      <PanelTabs
        placementId={placement.id}
        widgetProps={widgetProps}
        setWidgetProps={setWidgetProps}
        placementLayout={placement.placementLayout}
        handleDeletePanel={handleDeletePanel}
        showDeleteButton={placement.panels.length > 1}
      />
      <div>
        <div className="flex-row-center">
          <div className="flex-column">
            <h4
              className="component-header"
              style={{ marginTop: "15px", textAlign: "center" }}
            >
              PREVIEW
            </h4>
            <Preview
              widgetProps={widgetProps}
              placementId={placement.id}
              getPanelsPerRow={getPanelsPerRow}
              getPanelsRows={getPanelsRows}
            />
          </div>
        </div>
        <div>
          <div className="flex-row-center">
            <div className="flex-column">
              <Markup
                widgetProps={widgetProps}
                placementId={placement.id}
                getPanelsPerRow={getPanelsPerRow}
                getPanelsRows={getPanelsRows}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Placement;
