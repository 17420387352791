import React from "react";

function HideProvider({providerHidden, placementId, panelPosition, widgetProps, setWidgetProps}) {

    function handleHideProviderToggle() {
        const wProps = {...widgetProps};
        let newPlacements = [];
        let newPanels = [];

        widgetProps.placements.map(placement => {
            let newPlacement = placement;
            if(placement.id === placementId) {
                placement.panels.map(panel => {
                    const newPanel = panel;
                    if(panel.position === panelPosition) {
                        newPanel.providerHidden = !panel.providerHidden;
                    }
                    newPanels.push(newPanel);

                    return panel;
                })
                newPlacement.panels = newPanels;
            }
            newPlacements.push(newPlacement);

            return placement;
        })
        wProps.placements = newPlacements;

        setWidgetProps(wProps);
    }

    return(
        <>
            <label>Hide</label>
            <input type="checkbox" checked={providerHidden ? "checked" : ""} onChange={handleHideProviderToggle} />
        </>
    )
}

export default HideProvider;
