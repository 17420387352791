import React from "react";
import ColorPicker from "../colorpicker/ColorPicker";

function HeadlineColor({headlineColor, placementId, panelPosition, widgetProps, setWidgetProps}) {
    const HEADLINE_DEFAULT_COLOR = widgetProps.headlineDefaultColor;

    function handleChange(newColor) {
        const wProps = {...widgetProps};
        let newPlacements = [];
        let newPanels = [];

        widgetProps.placements.map(placement => {
            let newPlacement = placement;
            if(placement.id === placementId) {
                placement.panels.map(panel => {
                    let newPanel = panel;
                    if(panel.position === panelPosition) {
                        newPanel.headlineColor = newColor.replace("#", "");
                    }
                    newPanels.push(newPanel);

                    return panel;
                })
                newPlacement.panels = newPanels;
            }
            newPlacements.push(newPlacement);

            return placement;
        })

        wProps.placements = newPlacements;
        setWidgetProps(wProps);
    }

    return(
        <>
            <ColorPicker currentColor={headlineColor} defaultColor={HEADLINE_DEFAULT_COLOR} handleChange={handleChange} />
        </>
    )
}

export default HeadlineColor;
