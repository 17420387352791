import React from "react";
import Select from "./selectwithcustom/Select";
import Custom from "./selectwithcustom/Custom";

function SelectWithCustom({settings, isCustom, customValue, setCustomValue, handleSelect, handleCustom, customLabel}) {

    function handleCustomChange() {
        handleCustom();
    }

    return(
        <>
            <div className="flex-row-left">
                <div className="flex-column">
                    <label style={{margin: "2px 5px auto 5px"}}>{settings.labelName}:</label>
                </div>
                <div className="flex-column">
                    <Select selectedValue={isCustom ? 'custom' : settings.selectValue} mappedOptionPairs={settings.mappedOptionPairs} handleSelectChange={handleSelect} />
                </div>
                <div className="flex-column" style={{display: isCustom ? 'block' : 'none'}}>
                    <Custom customValue={customValue} setCustomValue={setCustomValue} handleCustomChange={handleCustomChange} customLabel={customLabel} />
                </div>
            </div>
        </>
    )
}

export default SelectWithCustom;
