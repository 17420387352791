import React, {useState} from "react";
import {ChromePicker} from "react-color";

function ColorPicker({currentColor, defaultColor, handleChange}) {
    const DEFAULT_COLOR = defaultColor;
    let activeColor = currentColor === "" ? DEFAULT_COLOR : currentColor;

    const [buttonColorPicker, setButtonColorPicker] = useState();
    const [previousColor, setPreviousColor] = useState();
    const [pickerMode, setPickerMode] = useState(false);

    function handleEdit() {
        if(currentColor === "") setButtonColorPicker(DEFAULT_COLOR);
        setPreviousColor(buttonColorPicker);
        setPickerMode(true);
    }

    function handleDefault() {
        setButtonColorPicker(DEFAULT_COLOR);
    }

    function handleSave() {
        setPickerMode(false);
        handleChange(buttonColorPicker);
    }

    function handleCancel() {
        setButtonColorPicker(previousColor);
        setPickerMode(false);
    }

    return(
        <>
            <div className="flex-row-left">
                <div className="flex-column">
                    <label id="learn-more-btn-color-label">Color:</label>
                </div>
                <div className="flex-column">
                    <div className="flex-row-left">
                        <div className="flex-column">
                            <div className="color-select-preview"  style={{background: activeColor}} />
                        </div>
                        <div className="flex-column">
                            <div className="color-select-display">
                                {activeColor}
                            </div>
                        </div>
                        <div className="flex-column">
                            <button type="button" className="settings-btn" onClick={handleEdit}>Edit</button>
                        </div>
                    </div>
                    <div className="flex-row-left" style={pickerMode ? {borderRadius: "5px", zIndex: 1, position: "absolute", background: "white", border: "1px solid black", paddingTop: "5px"} : {display: "none"}}>
                        <div className="flex-column">
                            <div className="flex-row-left">
                                <div className="flex-column">
                                    <button type="button" className="settings-btn" onClick={handleDefault}>Default</button>
                                </div>
                                <div className="flex-column" style={{width: "160px"}}>
                                    <div className="flex-row-right">
                                        <div className="flex-column">
                                            <button type="button" className="settings-btn" onClick={handleSave}>Save</button>
                                        </div>
                                        <div className="flex-column">
                                            <button type="button" className="settings-btn settings-btn-cancel" onClick={handleCancel}>Cancel</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="flex-row-left">
                                <div style={{marginTop: "5px"}}>
                                    <ChromePicker
                                        disableAlpha={true}
                                        color={buttonColorPicker}
                                        onChange={(color) => {
                                            setButtonColorPicker(color.hex);
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ColorPicker;
