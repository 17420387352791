import React from "react";
import Internal from "./panel/Internal";
import PanelLayout from "./panel/PanelLayout";
import SubIds from "./panel/SubIds";
import HideLearnMoreButton from "./panel/learnmorebutton/HideLearnMoreButton";
import LearnMoreButtonColor from "./panel/learnmorebutton/LearnMoreButtonColor";
import HideProvider from "./panel/provider/HideProvider";
import ProviderColor from "./panel/provider/ProviderColor";
import HeadlineColor from "./panel/headline/HeadlineColor";
import HeadlineSize from "./panel/headline/HeadlineSize";
import HeadlineFont from "./panel/headline/HeadlineFont";
import HeadlineFontStyle from "./panel/headline/HeadlineFontStyle";

function Panel({
  placementId,
  panelComponent,
  widgetProps,
  setWidgetProps,
  placementLayout
}) {
  const panel = { ...panelComponent };
  let learnMoreHidden = panel.learnMoreButtonHidden;
  let learnMoreColor =
    panel.learnMoreButtonColor !== "" ? "#" + panel.learnMoreButtonColor : "";
  const providerHidden = panel.providerHidden;
  const providerColor =
    panel.providerColor !== "" ? "#" + panel.providerColor : "";
  const headlineColor =
    panel.headlineColor !== "" ? "#" + panel.headlineColor : "";

  function handleToggleInternal(placementId, panelPosition) {
    const wProps = { ...widgetProps };

    let nextInternalOffset = 0;
    let nextSponsoredOffset = 0;
    let isToInternal = false;
    let newPlacements = [];
    wProps.placements.map((placement) => {
      let newPlacement = placement;
      let newPanels = [];
      newPlacement.panels = placement.panels.map((panel) => {
        let newPanel = panel;
        if (placement.id < placementId) {
          nextInternalOffset = panel.internal
            ? nextInternalOffset + 1
            : nextInternalOffset;
          nextSponsoredOffset = panel.internal
            ? nextSponsoredOffset
            : nextSponsoredOffset + 1;
        } else if (placement.id === placementId) {
          if (panel.position < panelPosition) {
            nextInternalOffset = panel.internal
              ? nextInternalOffset + 1
              : nextInternalOffset;
            nextSponsoredOffset = panel.internal
              ? nextSponsoredOffset
              : nextSponsoredOffset + 1;
          } else if (panel.position === panelPosition) {
            newPanel.internal = !newPanel.internal;
            isToInternal = newPanel.internal;
            newPanel.offset = isToInternal
              ? nextInternalOffset
              : nextSponsoredOffset;
          } else {
            if (isToInternal) {
              newPanel.offset = newPanel.internal
                ? newPanel.offset + 1
                : newPanel.offset - 1;
            } else {
              newPanel.offset = newPanel.internal
                ? newPanel.offset - 1
                : newPanel.offset + 1;
            }
          }
        } else {
          if (isToInternal) {
            newPanel.offset = newPanel.internal
              ? newPanel.offset + 1
              : newPanel.offset - 1;
          } else {
            newPanel.offset = newPanel.internal
              ? newPanel.offset - 1
              : newPanel.offset + 1;
          }
        }

        return newPanels.push(newPanel);
      });

      newPlacement.panels = newPanels;
      newPlacements.push(newPlacement);

      return placement;
    });

    wProps.placements = newPlacements;
    setWidgetProps(wProps);
  }

  function handlePanelLayout(placementId, panelPosition, layoutValue) {
    const wProps = { ...widgetProps };
    wProps.placements.map((placement) => {
      if (placement.id === placementId) {
        placement.panels.map((panel) => {
          if (panel.position === panelPosition) {
            panel.panelLayout = layoutValue;
            panel.learnMoreButtonColor = "";
          }
          return panel;
        });
      }
      return placement;
    });
    setWidgetProps(wProps);
  }

  const hideLearnMoreSettings =
    panel.panelLayout === "4" ||
    panel.panelLayout === "5" ||
    panel.panelLayout === "7" ||
    panel.panelLayout === "8";
  const hideHeadlineSettings =
    panel.panelLayout === "4" || panel.panelLayout === "8";
  const hideProviderSettings =
    panel.panelLayout === "4" || panel.panelLayout === "8";

  return (
    <>
      <div className="flex-row-left">
        <div className="flex-column">
          <div className="settings-item">
            <Internal
              isInternal={panel.internal}
              handleToggleInternal={handleToggleInternal}
              placementId={placementId}
              panelPosition={panel.position}
            />
          </div>
        </div>
        <div className="flex-column">
          <div
            className="settings-item"
            style={
              placementLayout === "custom"
                ? { display: "block" }
                : { display: "none" }
            }
          >
            <PanelLayout
              placementId={placementId}
              panelPosition={panel.position}
              handlePanelLayout={handlePanelLayout}
              panelLayoutValue={panel.panelLayout}
            />
          </div>
        </div>
      </div>
      <div className="flex-row-left">
        <div
          className="flex-column"
          style={hideLearnMoreSettings ? { display: "none" } : {}}
        >
          <div className="flex-row-left">
            <div className="flex-column group-label">
              <div>Button:</div>
            </div>
            <div className="flex-column">
              <div className="settings-item">
                <HideLearnMoreButton
                  learnMoreButtonHidden={learnMoreHidden}
                  placementId={placementId}
                  panelPosition={panel.position}
                  widgetProps={widgetProps}
                  setWidgetProps={setWidgetProps}
                />
              </div>
            </div>
            <div
              className="flex-column"
              style={
                !learnMoreHidden ? { display: "block" } : { display: "none" }
              }
            >
              <div className="settings-item">
                <LearnMoreButtonColor
                  learnMoreButtonColor={learnMoreColor}
                  placementId={placementId}
                  panelPosition={panel.position}
                  widgetProps={widgetProps}
                  setWidgetProps={setWidgetProps}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="flex-row-left"
        style={hideHeadlineSettings ? { display: "none" } : {}}
      >
        <div className="flex-column">
          <div className="flex-column group-label">
            <div className="settings-item">Headline:</div>
          </div>
        </div>
        <div className="flex-column">
          <div className="flex-row-left">
            <div className="flex-column">
              <div className="settings-item">
                <HeadlineColor
                  headlineColor={headlineColor}
                  placementId={placementId}
                  panelPosition={panel.position}
                  widgetProps={widgetProps}
                  setWidgetProps={setWidgetProps}
                />
              </div>
            </div>
            <div className="flex-column">
              <div className="settings-item">
                <HeadlineFont
                  panel={panel}
                  placementId={placementId}
                  panelPosition={panel.position}
                  widgetProps={widgetProps}
                  setWidgetProps={setWidgetProps}
                />
              </div>
            </div>
          </div>
          <div className="flex-row-left">
            <div className="flex-column">
              <div className="settings-item">
                <HeadlineSize
                  headlineSize={panel.headlineSize}
                  placementId={placementId}
                  panelPosition={panel.position}
                  widgetProps={widgetProps}
                  setWidgetProps={setWidgetProps}
                />
              </div>
            </div>
            <div className="flex-column">
              <div className="settings-item">
                <HeadlineFontStyle
                  panel={panel}
                  placementId={placementId}
                  panelPosition={panel.position}
                  widgetProps={widgetProps}
                  setWidgetProps={setWidgetProps}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="flex-row-left"
        style={hideProviderSettings ? { display: "none" } : {}}
      >
        <div className="flex-column group-label">
          <div className="settings-item">Provider:</div>
        </div>
        <div className="flex-column">
          <div className="settings-item">
            <HideProvider
              providerHidden={providerHidden}
              placementId={placementId}
              panelPosition={panel.position}
              widgetProps={widgetProps}
              setWidgetProps={setWidgetProps}
            />
          </div>
        </div>
        <div
          className="flex-column"
          style={!providerHidden ? { display: "block" } : { display: "none" }}
        >
          <div className="settings-item">
            <ProviderColor
              providerColor={providerColor}
              placementId={placementId}
              panelPosition={panel.position}
              widgetProps={widgetProps}
              setWidgetProps={setWidgetProps}
            />
          </div>
        </div>
      </div>
      <div className="flex-row-left">
        <div className="flex-column">
          <SubIds
            widgetProps={widgetProps}
            setWidgetProps={setWidgetProps}
            placementId={placementId}
            panelPosition={panel.position}
          />
        </div>
      </div>
    </>
  );
}

export default Panel;
