import React, {useState} from "react";
import SelectWithCustom from "../textselector/SelectWithCustom";

/* Note: if you change the first item in this list, update the 'uuid' value in the default widget properties array, 'initialState', found in Widget.js */
const uuids = [
    {key: 'Active Campaign', value: '%CAMPAIGNID%%CONTACTID%%LASTNAME%'},
    {key: 'AWeber', value: '{!firstname}{!lastname}'},
    {key: 'Campaign Monitor', value: '[firstname][lastname]'},
    {key: 'Constant Contact', value: '[[FirstName]][[LastName]]'},
    {key: 'Emma', value: '[% member:member_id default="email" %][% date:day %]'},
    {key: 'Get Response', value: '{{CONTACT "campaign_id"}}{{CONTACT "subscriber_id"}}{{DATE "YEAR-MONTH-DAY-HOUR-SECOND"}}'},
    {key: 'HubSpot', value: '{{contact.lastname}}{{contact.firstname}}{{content.campaign}}'},
    {key: 'iContact', value: '[fname][lname]'},
    {key: 'MailChimp', value: '*|CAMPAIGN_UID|**|FNAME|**|LNAME|*'},
    {key: 'MailGun', value: '%recipient.unique_id%'},
    {key: 'MailJet', value: '[[data:firstname]][[data:lastname]]'},
    {key: 'MaroPost', value: '{{contact.id}}{{campaign.id}}'},
    {key: 'SailThru', value: '{last_name}{emailnum}'},
    {key: 'SalesForce', value: '%%first_name%%%%last_name%%'},
    {key: 'Second Street', value: '{{System.Random1}}{{User.UniqueId}}'},
    {key: 'Sendgrid', value: '{{firstname}}{{lastname}}'},
    {key: 'Sendy', value: '[Name][currentdaynumber]'},
    {key: 'Sendlane', value: 'VAR_FIRST_NAME_VAR_LAST_NAME_VAR_CAMPAIGN_ID'},
    {key: 'SparkPost', value: '{{email_id}}{{message_id}}'},
    {key: 'Upland PostUp', value: '[-RECIPID-][-MAILINGID-]'},
    {key: 'Custom Key', value: 'custom'}
];

function Uuid({widgetProps, setWidgetProps}) {
    const isCustom = widgetProps.isCustomKey;
    const [customValue, setCustomValue] = useState(isCustom ? widgetProps.uuid : '');

    function handleSelect(value) {
        const props = {};
        setCustomValue("");

        if(value !== 'custom') {
            props.uuid = value;
            props.isCustomKey = false;
        } else {
            props.uuid = "";
            props.isCustomKey = true;
        }

        setKeyProperties(props);
    }

    function handleCustom() {
        const props = {};
        props.uuid = customValue;
        props.isCustomKey = true;
        setKeyProperties(props);
    }

    function setKeyProperties(props) {
        const wProps = {...widgetProps};
        wProps.uuid = props.uuid;
        wProps.isCustomKey = props.isCustomKey;
        setWidgetProps(wProps);
    }

    const uuidProps = {};
    uuidProps.labelName = "Service Provider";
    uuidProps.selectValue = widgetProps.uuid;
    uuidProps.mappedOptionPairs = uuids;

    return(
        <>
            <div>
                <SelectWithCustom settings={uuidProps} isCustom={isCustom} customValue={customValue}
                                  setCustomValue={setCustomValue} handleSelect={handleSelect}
                                  handleCustom={handleCustom} />
            </div>
        </>
    );
}

export default Uuid;
