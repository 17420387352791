import React from "react";
import WidgetId from "./inputs/settings/WidgetId";
import Uuid from "./inputs/settings/Uuid";

function Settings({ widgetProps, setWidgetProps }) {
  return (
    <>
      <div className="row">
        <div className="column-left"></div>
        <div className="column-right">
          <div style={{ float: "right" }}>
            <a className="help-link"
              href="https://help.revcontent.com/knowledge/revmail-documentation"
              target="_blank"
              rel="noreferrer"
            >
              <span role="img" aria-label="Info">
                ℹ️
              </span>{" "}
              Documentation
            </a>
          </div>
        </div>
      </div>
      <div className="flex-row-left required-container">
        <WidgetId widgetProps={widgetProps} setWidgetProps={setWidgetProps} />
        <Uuid widgetProps={widgetProps} setWidgetProps={setWidgetProps} />
      </div>
    </>
  );
}

export default Settings;
