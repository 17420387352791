import React from "react";
import {getHelpLink, getPanelLayoutOptions} from "../layouts";

function PanelLayout({placementId, panelPosition, handlePanelLayout, panelLayoutValue}) {
    let helpLink = getHelpLink(panelLayoutValue);

    return(
        <>
            <label className="group-label">Layout:</label>
            <select value={panelLayoutValue} style={{marginLeft: "5px"}} onChange={(event) => {
                handlePanelLayout(placementId, panelPosition, event.target.value);
            }}>
                {getPanelLayoutOptions()}
            </select>
            <a className="help-link" href={helpLink} target="_blank" rel="noreferrer">Help</a>
        </>
    );
}

export default PanelLayout;
