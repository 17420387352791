import React from "react";

const widths = [
    {key: '200px', value: '200'},
    {key: '300px', value: '300'},
    {key: '400px', value: '400'},
    {key: '500px', value: '500'},
    {key: '600px', value: '600'},
    {key: '700px', value: '700'},
    {key: '800px', value: '800'},
    {key: '900px', value: '900'},
    {key: '1000px', value: '1000'},
    {key: '1100px', value: '1100'},
    {key: '1200px', value: '1200'},
    {key: '1300px', value: '1300'}
];

function MaxWidth({handleMaxWidth, maxWidthValue}) {

    function getMaxWidthOptions() {
        return (
            <>
                {widths.map(w => (
                    <option key={w.key} value={w.value}>{w.key}</option>
                ))}
            </>
        )
    }

    return (
        <>
            <div className="flex-row-left" style={{margin: "auto 5px 0 5px"}}>
                <div className="flex-column">
                    <label className="group-label">Maximum Width:</label>
                </div>
                <div className="flex-column" style={{margin: "auto 0 0 5px"}}>
                    <select value={maxWidthValue} onChange={(event) => {
                        handleMaxWidth(event.target.value);
                    }}>
                        {getMaxWidthOptions()}
                    </select>
                </div>
                <div className="flex-column" style={{margin: "auto 5px 0 5px", fontStyle: "italic"}} >
                    (includes margins/padding)
                </div>
            </div>
        </>
    )
}

export default MaxWidth;
