import React from "react";

function Internal({
  isInternal,
  handleToggleInternal,
  placementId,
  panelPosition
}) {
  return (
    <>
      <label className="group-label">Enable Internal Content:&nbsp;</label>
      <input
        type="checkbox"
        checked={isInternal ? "checked" : ""}
        onChange={() => handleToggleInternal(placementId, panelPosition)}
      />
    </>
  );
}

export default Internal;
