import React from "react";
import {v4 as uuidv4} from "uuid";
import {getLinks} from "./common";

function GetPanelHtml({wProps, panel, imageSize}) {
    const p = {...panel};
    const key = panel.internal ? "&key=" + wProps.internalKey : "&key=" + wProps.key;
    const subIds = "";

    const widgetProps = {...wProps};
    const links = getLinks(widgetProps, key, p, imageSize, subIds);

    return (
        <>
            <a href={links.clickLink}>
                <img src={links.imgSrc} alt="Preview not available"/>
            </a>
        </>
    );
}

function GetRow({row, wProps, size}) {
    return (
        <>
            <tr>
                {
                    row.map(panel => (
                        <td key={uuidv4()}>
                            <table>
                                <tbody>
                                        <tr>
                                            <td>
                                                <div>
                                                    <GetPanelHtml wProps={wProps} panel={panel} imageSize={size} />
                                                </div>
                                            </td>
                                        </tr>
                                </tbody>
                            </table>
                        </td>
                    ))
                }
            </tr>
        </>
    );
}

function GetRows({rowList, wProps, size}) {
    return(
        <>
            {
                rowList.map(row => (
                    <GetRow key={uuidv4()} row={row} wProps={wProps} size={size} />
                ))
            }
        </>
    )
}

function GetPanels({wProps, placementId, getPanelsPerRow, getPanelsRows}) {
    let size;
    let panelsPerRow;
    wProps.placements.map(placement => {
        if(placement.id === placementId) {
            panelsPerRow = getPanelsPerRow(placement);
            size = placement.size;
        }
        return placement;
    })

    return (<GetRows rowList={getPanelsRows(placementId, panelsPerRow)} wProps={wProps} size={size} />);
}

function GetPreview({widgetProps, placementId, getPanelsPerRow, getPanelsRows}) {
    const wProps = {...widgetProps};
    if(!wProps.widgetId) return (<p className="preview-message">Widget ID needed</p>);

    wProps.key = "rc_test_" + uuidv4();

    return (
        <>
            <table>
                <tbody>
                    <GetPanels wProps={wProps} placementId={placementId} getPanelsPerRow={getPanelsPerRow} getPanelsRows={getPanelsRows} />
                </tbody>
            </table>
        </>
    );
}

export function Preview({widgetProps, placementId, getPanelsPerRow, getPanelsRows}) {
    return (
        <>
            <div className="component-container standard-bg" id="preview-container" >
                <GetPreview widgetProps={widgetProps} placementId={placementId} getPanelsPerRow={getPanelsPerRow} getPanelsRows={getPanelsRows} />
            </div>
        < />
    );
}
