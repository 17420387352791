import React from "react";
/*
        DON'T FORGET NEW LAYOUT 7
        I just pushed out an update for hatemail that adds a new layout (7) to the system per Chad’s request. in
        this mode thumbnails size is fixed and headline/provider text is appended to the bottom (can have variable height).
        please try it out and let me know if you see any issues or want to change anything
        http://hatemail.revcontent.com/?id=112881&key=key1key&offset=2&size=130x130&layout=7&hide_button=true&headline_size=14&headline_font=Roboto&headline_font_style=Bold&total=4

        https://help.revcontent.com/knowledge/html-ad-units

https://help.revcontent.com/knowledge/revmail-documentation#layout-6
 */

const layouts = [
    {key: "1", value: "1", helpLink: "https://help.revcontent.com/knowledge/revmail-documentation#layout-1"},
    {key: "2", value: "2", helpLink: "https://help.revcontent.com/knowledge/revmail-documentation#layout-2"},
    {key: "3", value: "3", helpLink: "https://help.revcontent.com/knowledge/revmail-documentation#layout-3"},
    {key: "4", value: "4", helpLink: "https://help.revcontent.com/knowledge/revmail-documentation#layout-4"},
    {key: "5", value: "5", helpLink: "https://help.revcontent.com/knowledge/revmail-documentation#layout-5"},
    {key: "7", value: "7", helpLink: "https://help.revcontent.com/knowledge/revmail-documentation#layout-7"},
    {key: "8", value: "8", helpLink: "https://help.revcontent.com/knowledge/revmail-documentation#layout-8"},
    {key: "Custom", value: "custom", helpLink: ""}
];

export function getPanelLayoutOptions() {
    let options = [];

    layouts.map(layout => {
        if(layout.key !== "Custom") {
            options.push(layout);
        }

        return layout;
    })

    return(
        <>
            {options.map(option => (
                <option key={option.key} value={option.value}>{option.key}</option>
            ))}
        </>
    );
}

export function getPlacementLayoutOptions() {
    return(
        <>
            {layouts.map(layout => (
                <option key={layout.key} value={layout.value}>{layout.key}</option>
            ))}
        </>
    );
}

export function getHelpLink(placementLayoutValue) {
    let helpLink = "";

    layouts.map(layout => {
        if(layout.value === placementLayoutValue) {
            helpLink = layout.helpLink;
        }

        return layout;
    });

    return helpLink;
}
