import React from "react";
import { getHelpLink, getPlacementLayoutOptions } from "./layouts";

function PlacementLayout({ handleLayout, placementLayoutValue }) {
  const helpLink = getHelpLink(placementLayoutValue);

  return (
    <>
      <div className="flex-row-left layout-label">
        <div className="flex-column">
          <label className="group-label">Layout:</label>
        </div>
        <div className="flex-column" style={{ margin: "0px 5px 0px 7px" }}>
          <select
            value={placementLayoutValue}
            onChange={(event) => {
              handleLayout(event.target.value);
            }}
          >
            {getPlacementLayoutOptions()}
          </select>
        </div>
        <div className="flex-column">
          <a
            href={helpLink}
            target="_blank"
            rel="noreferrer"
            style={
              helpLink === ""
                ? { display: "none" }
                : {
                    fontSize: "14px",
                    marginLeft: "5px",
                    textDecoration: "none"
                  }
            }
          >
            Help
          </a>
        </div>
      </div>
    </>
  );
}

export default PlacementLayout;