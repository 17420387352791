import React from "react";
import "./styles/App.css";
import Widget from "./components/Widget";

function App() {
  return (
    <>
      <div>
        <div id="page-logo">
          <a href="https://revcontent.com/">
            <img
              width="400"
              height="61"
              src="https://www.revcontent.com/assets/img/revcontent-logo-BK_GR.svg"
              alt="RevContent"
              loading="eager"
            />
          </a>
        </div>
        <h1 id="app-title">RevMail Builder</h1>
      </div>

      <div className="flex-row-center">
        <div>
          <Widget />
        </div>
      </div>
    </>
  );
}

export default App;