import React from "react";

function HeadlineSize({headlineSize, placementId, panelPosition, widgetProps, setWidgetProps}) {
    let headlineSizeLocked = false;
    widgetProps.placements.map(placement => {
        if(placement.id === placementId) {
            headlineSizeLocked = placement.headlineSizeLocked;
        }
        return placement;
    })

    const disableHeadlineSize = headlineSizeLocked ? "disabled" : "";
    const sizeLabelClass = headlineSizeLocked ? "label-lightgray" : "label-black";
    const hideSizeToolTip = headlineSizeLocked ? "" : "hidden";

    function handleSelectSize(size) {
        const wProps = {...widgetProps};
        let newPlacements = [];
        let newPanels = [];

        widgetProps.placements.map(placement => {
            let newPlacement = placement;
            if(placement.id === placementId) {
                placement.panels.map(panel => {
                    let newPanel = panel;
                    if(panel.position === panelPosition) {
                        newPanel.headlineSize = size;
                    }
                    newPanels.push(newPanel);

                    return panel;
                })
                newPlacement.panels = newPanels;
            }
            newPlacements.push(newPlacement);

            return placement;
        })

        wProps.placements = newPlacements;
        setWidgetProps(wProps);
    }

    function getSizeArray() {
        let sizes = [];

        for(let i = widgetProps.minHeadlineSize; i <= widgetProps.maxHeadlineSize; i++) {
            sizes.push(i.toString());
        }

        return sizes;
    }

    function SizeOption() {
        const sizes = getSizeArray();

        return (
            <>
                {
                    sizes.map(size => (
                        <option key={size} style={{paddingLeft: "10px", paddingRight: "10px"}}>{size}</option>
                    ))
                }
            </>
        );
    }

    return(
        <>
            <div className="tooltip">
                <label className={sizeLabelClass} style={{margin: "6px 5px auto auto"}}>Size:</label>
                <select disabled={disableHeadlineSize} value={headlineSize} onChange={(event) => {
                    handleSelectSize(event.target.value);
                }}>
                    <SizeOption />
                </select>
                <span className="tool-tip-text" hidden={hideSizeToolTip}>Headline size locked due to Image size</span>
            </div>
        </>
    );
}

export default HeadlineSize;
