import React from "react";
import ColorPicker from "../colorpicker/ColorPicker";

function LearnMoreButtonColor({
  learnMoreButtonColor,
  placementId,
  panelPosition,
  widgetProps,
  setWidgetProps
}) {
  const LEARN_MORE_BUTTON_DEFAULT_COLOR =
    widgetProps.learnMoreButtonDefaultColor;

  function handleChange(newColor) {
    const wProps = { ...widgetProps };
    let newPlacements = [];
    let newPanels = [];

    widgetProps.placements.map((placement) => {
      let newPlacement = placement;
      if (placement.id === placementId) {
        placement.panels.map((panel) => {
          let newPanel = panel;
          if (panel.position === panelPosition) {
            newPanel.learnMoreButtonColor = newColor.replace("#", "");
          }
          newPanels.push(newPanel);

          return panel;
        });
        newPlacement.panels = newPanels;
      }
      newPlacements.push(newPlacement);

      return placement;
    });

    wProps.placements = newPlacements;
    setWidgetProps(wProps);
  }

  return (
    <>
      <ColorPicker
        currentColor={learnMoreButtonColor}
        defaultColor={LEARN_MORE_BUTTON_DEFAULT_COLOR}
        handleChange={handleChange}
      />
    </>
  );
}

export default LearnMoreButtonColor;