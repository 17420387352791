import React from "react";

function HideLearnMoreButton({
  learnMoreButtonHidden,
  placementId,
  panelPosition,
  widgetProps,
  setWidgetProps
}) {
  function handleHideLearnMoreButtonToggle() {
    const wProps = { ...widgetProps };
    let newPlacements = [];
    let newPanels = [];

    widgetProps.placements.map((placement) => {
      let newPlacement = placement;
      if (placement.id === placementId) {
        placement.panels.map((panel) => {
          let newPanel = panel;
          if (panel.position === panelPosition) {
            newPanel.learnMoreButtonHidden = !panel.learnMoreButtonHidden;
          }
          newPanels.push(newPanel);

          return panel;
        });
        newPlacement.panels = newPanels;
      }
      newPlacements.push(newPlacement);

      return placement;
    });
    wProps.placements = newPlacements;

    setWidgetProps(wProps);
  }

  return (
    <>
      <label>&nbsp;&nbsp;Hide</label>
      <input
        type="checkbox"
        checked={learnMoreButtonHidden ? "checked" : ""}
        onChange={handleHideLearnMoreButtonToggle}
      />
    </>
  );
}

export default HideLearnMoreButton;