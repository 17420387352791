import React from "react";
import { v4 as uuidv4 } from "uuid";

function SubIds({ widgetProps, setWidgetProps, placementId, panelPosition }) {
  const [key, setKey] = React.useState("");
  const [value, setValue] = React.useState("");

  function handleKeyChange(event) {
    setKey(event.target.value);
  }

  function handleValueChange(event) {
    setValue(event.target.value);
  }

  function handleAdd() {
    const wProps = { ...widgetProps };
    let newPlacements = [];
    let newPanels = [];
    let subIdArray;

    widgetProps.placements.map((placement) => {
      let newPlacement = placement;
      if (placement.id === placementId) {
        placement.panels.map((panel) => {
          let newPanel = panel;
          if (panel.position === panelPosition) {
            subIdArray = stringToArray(panel.subIds);
            subIdArray.push(key + ":" + value);
            newPanel.subIds = arrayToString(subIdArray);
          }
          newPanels.push(newPanel);

          return panel;
        });
        newPlacement.panels = newPanels;
      }
      newPlacements.push(newPlacement);

      return placement;
    });

    wProps.placements = newPlacements;

    setKey("");
    setValue("");

    return wProps;
  }

  function handleRemove({ item }) {
    const wProps = { ...widgetProps };
    let newPlacements = [];
    let newPanels = [];
    const newSubIdArray = [];
    let subIdArray;

    widgetProps.placements.map((placement) => {
      let newPlacement = placement;
      if (placement.id === placementId) {
        placement.panels.map((panel) => {
          let newPanel = panel;
          if (panel.position === panelPosition) {
            subIdArray = stringToArray(panel.subIds);
            subIdArray.map((mapItem) =>
              mapItem !== item ? newSubIdArray.push(mapItem) : {}
            );
            newPanel.subIds = arrayToString(newSubIdArray);
          }
          newPanels.push(newPanel);

          return panel;
        });
        newPlacement.panels = newPanels;
      }
      newPlacements.push(newPlacement);

      return placement;
    });

    wProps.placements = newPlacements;

    return wProps;
  }

  function stringToArray(subIdsString) {
    const emptyArray = [];
    const str = subIdsString.replace("[", "").replace("]", "");
    return subIdsString === "" ? emptyArray : str.split(",");
  }

  function arrayToString(subIdsArray) {
    let items = "";
    subIdsArray.map((item) => (items += item + ","));

    return subIdsArray.length > 0 ? "[" + items.slice(0, -1) + "]" : "";
  }

  function SubIdListItems() {
    let subIds = "";

    widgetProps.placements.map((placement) => {
      if (placement.id === placementId) {
        placement.panels.map((panel) => {
          if (panel.position === panelPosition) {
            subIds = panel.subIds;
          }

          return panel;
        });
      }

      return placement;
    });

    const subIdArray = stringToArray(subIds);

    return (
      <>
        {subIdArray.map((item) => (
          <li className="sub-id-item" key={uuidv4()}>
            {item}{" "}
            <button
              onClick={(event) => {
                setWidgetProps(handleRemove({ item }));
              }}
            >
              Remove
            </button>
          </li>
        ))}
      </>
    );
  }

  return (
    <>
      <div className="settings-item">
        <div className="sub-id-input-container">
          <label className="group-label">
            SubID Tracking:&nbsp;&nbsp;&nbsp;
          </label>

          <label>Key:</label>
          <input
            className="settings-input-item"
            type="text"
            value={key}
            onChange={handleKeyChange}
          />
          <label>Value:</label>
          <input
            className="settings-input-item"
            type="text"
            value={value}
            onChange={handleValueChange}
          />
          <button
            type="button"
            className="settings-btn"
            onClick={() => {
              setWidgetProps(handleAdd());
            }}
          >
            Add
          </button>
        </div>
        <div>
          <ul className="sub-id-values-list">
            <SubIdListItems />
          </ul>
        </div>
      </div>
    </>
  );
}

export default SubIds;
