import {fixedHeadlineSizes} from "../../Widget";

export function getLinks(wProps, uuid, panel, imageSize, subIdList) {
    const widgetProps = {...wProps};

    let links = {};
    const domain = "https://revmail.revcontent.com/";

    const id = "?id=" + widgetProps.widgetId;
    const key = uuid;
    const total = panel.internal ? "&total=" + widgetProps.panelTotals.totalInternal : "&total=" + widgetProps.panelTotals.totalSponsored;
    const offset = "&offset=" + panel.offset;
    const layout = panel.panelLayout !== "1" ? "&layout=" + panel.panelLayout : "";
    const size = "&size=" + imageSize;
    const internal = panel.internal ? "&internal=true" : "";

    const learnMoreButtonHidden = panel.learnMoreButtonHidden && (panel.panelLayout === "1" || panel.panelLayout === "2" || panel.panelLayout === "3")
        ? "&hide_button=true"
        : "";
    const learnMoreButtonColor =
        panel.learnMoreButtonColor !== ""
        && !panel.learnMoreButtonHidden
        && (panel.panelLayout === "1" || panel.panelLayout === "2" || panel.panelLayout === "3")
        && panel.learnMoreButtonColor !== wProps.learnMoreButtonDefaultColor.replace("#", "")
            ? "&button_color=" + panel.learnMoreButtonColor
            : "";

    const providerHidden = panel.providerHidden ? "&hide_provider=true" : "";
    const providerColor = panel.providerColor !== "" && !panel.providerHidden && panel.providerColor !== wProps.providerDefaultColor.replace("#", "")
        ? "&provider_color=" + panel.providerColor
        : "";

    const headlineColor = panel.headlineColor !== "" && panel.headlineColor !== wProps.headlineDefaultColor
        ? "&headline_color=" + panel.headlineColor
        : "";
    const headlineSize = !(imageSize in fixedHeadlineSizes) && panel.headlineSize !== "" && panel.headlineSize !== wProps.defaultHeadlineSize
        ? "&headline_size=" + panel.headlineSize
        : "";
    const headlineFont = panel.headlineFont !== "" && panel.headlineFont !== wProps.defaultHeadlineFont ? "&headline_font=" + panel.headlineFont : "";
    const headlineFontStyle = panel.headlineFontStyle !== "none" ? "&headline_font_style=" + panel.headlineFontStyle : "";

    const subIds = subIdList === "" ? "" : "&sub_ids=" + panel.subIds;

    links.imgSrc = domain + id + key + total + offset + layout + size + internal + learnMoreButtonHidden + learnMoreButtonColor
        + providerHidden + providerColor + headlineColor + headlineSize + headlineFont + headlineFontStyle + subIds;

    links.clickLink = domain + "click/" + id + key + total + offset + subIds;

    return links;
}