import React, {useState} from "react";
import SelectWithCustom from "../../../textselector/SelectWithCustom";

const headlineFonts = [
    {key: "Arial", value: "arial"},
    {key: "Georgia", value: "georgia"},
    {key: "Helvetica", value: "helvetica"},
    {key: "Roboto", value: "roboto"},
    {key: "Verdana", value: "verdana"},
    {key: "Custom", value: "custom"}
];

function HeadlineFont({panel, placementId, panelPosition, widgetProps, setWidgetProps}) {
    const isCustom = panel.isCustomFont;
    const [customValue, setCustomValue] = useState(isCustom ? panel.headlineFont : '');

    function handleSelect(value) {
        const props = {};
        setCustomValue("");

        if(value !== 'custom') {
            props.headlineFont = value;
            props.isCustomFont = false;
        } else {
            props.headlineFont = "";
            props.isCustomFont = true;
        }

        setHeadlineProperties(props);
    }

    function handleCustom() {
        const props = {};
        props.headlineFont = customValue;
        props.isCustomFont = true;
        setHeadlineProperties(props);
    }

    function setHeadlineProperties(props) {
        const wProps = {...widgetProps};
        let newPlacements = [];
        let newPanels = [];

        widgetProps.placements.map(placement => {
            let newPlacement = placement;
            if(placement.id === placementId) {
                placement.panels.map(panel => {
                    let newPanel = panel;
                    if(panel.position === panelPosition) {
                        newPanel.headlineFont = props.headlineFont;
                        newPanel.isCustomFont = props.isCustomFont;
                    }
                    newPanels.push(newPanel);

                    return panel;
                })
                newPlacement.panels = newPanels;
            }
            newPlacements.push(newPlacement);

            return placement;
        })

        wProps.placements = newPlacements;
        setWidgetProps(wProps);
    }

    const hlFontProps = {};
    hlFontProps.labelName = "Font";
    hlFontProps.selectValue = panel.headlineFont;
    hlFontProps.mappedOptionPairs = headlineFonts;

    return(
        <>
            <div>
                <SelectWithCustom settings={hlFontProps} isCustom={isCustom} customValue={customValue}
                                  setCustomValue={setCustomValue} handleSelect={handleSelect}
                                  handleCustom={handleCustom} customLabel="Custom Font" />
            </div>
        </>
    );
}

export default HeadlineFont;
