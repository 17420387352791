import React, { useState } from "react";
import { Tab, TabPanel, Tabs, TabList } from "react-tabs";
import Panel from "./inputs/placement/Panel";

function PanelTabs({
  placementId,
  widgetProps,
  setWidgetProps,
  placementLayout,
  handleDeletePanel,
  showDeleteButton
}) {
  const [selectedPanel, setSelectedPanel] = useState(0);

  let panels;
  widgetProps.placements.map((placement) => {
    if (placement.id === placementId) {
      panels = placement.panels;
    }

    return placement;
  });

  function deletePanel(placementId, position) {
    setSelectedPanel(0);
    handleDeletePanel(placementId, position);
  }

  function DeletePanelButton({ placementId, position, showButton }) {
    return (
      <>
        <div className="flex-row-right">
          <div className="flex-column">Delete Panel</div>
          <div className="flex-column">
            <button
              className="container-header-del-btn"
              style={showButton ? { display: "block" } : { display: "none" }}
              onClick={() => deletePanel(placementId, position)}
            >
              x
            </button>
          </div>
        </div>
      </>
    );
  }

  function toggleOptionalSettings(placementId, panelPosition) {
    const wProps = { ...widgetProps };

    wProps.placements.map((placement) => {
      if (placement.id === placementId) {
        placement.panels.map((panel) => {
          if (panel.position === panelPosition) {
            panel.optionalSettingsHidden = !panel.optionalSettingsHidden;
          }
          return panel;
        });
      }
      return placement;
    });

    setWidgetProps(wProps);
  }

  return (
    <>
      <Tabs
        selectedIndex={selectedPanel}
        onSelect={(panel) => setSelectedPanel(panel)}
      >
        <TabList>
          {panels.map((panel) => (
            <Tab key={panel.position}>Panel {panel.position}</Tab>
          ))}
        </TabList>
        {panels.map((panel) => (
          <TabPanel key={panel.position}>
            <div className="row">
              <div className="column-left" style={{ marginLeft: "5px" }}>
                <div className="flex-row-left">
                  <div className="flex-column">
                    <h4 className="component-header">OPTIONAL SETTINGS</h4>
                  </div>
                  <div className="flex-column">
                    <div className="flex-column">
                      <button
                        className="hide-show-btn"
                        onClick={() =>
                          toggleOptionalSettings(placementId, panel.position)
                        }
                      >
                        <img className="hide-show-img" src={process.env.PUBLIC_URL + (panel.optionalSettingsHidden ? "/images/down_arrow.png" : "/images/up_arrow.png")} alt="Arrow"/>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="column-right"
                style={
                  showDeleteButton ? { display: "block" } : { display: "none" }
                }
              >
                <div style={{ float: "right" }}>
                  <DeletePanelButton
                    placementId={placementId}
                    position={panel.position}
                    showButton={true}
                  />
                </div>
              </div>
            </div>
            <div
              className="optional-container"
              style={panel.optionalSettingsHidden ? { display: "none" } : {}}
            >
              <Panel
                placementId={placementId}
                panelComponent={panel}
                widgetProps={widgetProps}
                setWidgetProps={setWidgetProps}
                placementLayout={placementLayout}
              />
            </div>
          </TabPanel>
        ))}
      </Tabs>
    </>
  );
}

export default PanelTabs;
