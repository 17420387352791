import React from "react";

function Custom({customValue, setCustomValue, handleCustomChange, customLabel}) {

    function handleValueChange(event) {
        setCustomValue(event.target.value);
    }

    function handleApply() {
        handleCustomChange(customValue);
    }

    const placeholderText = customLabel === "Custom Font" ? "Font Family Input" : "";

    return(
        <>
            <div className="flex-row-left">
                <div className="flex-column">
                    <label className="group-label" style={{marginLeft: "5px", marginRight: "5px"}} >{customLabel}:</label>
                </div>
                <div className="flex-column">
                    <label>
                        <input type="text" value={customValue} onChange={handleValueChange} placeholder={placeholderText} />
                    </label>
                </div>
                <div className="flex-column" style={{marginLeft: "5px"}}>
                    <button className="settings-btn" type="button" onClick={handleApply}>
                        Apply
                    </button>
                </div>
            </div>
        </>
    )
}

export default Custom;
