import React from "react";
import {v4 as uuidv4} from "uuid";

function Option({mappedOptionPairs}) {
    return(
        mappedOptionPairs.map(option => (
            <option key={uuidv4()} value={encodeURI(option.value)}>{option.key}</option>
        ))
    )
}

function Select({selectedValue, mappedOptionPairs, handleSelectChange}) {
    return(
        <>
            <select value={selectedValue} style={{marginTop: "2px"}} onChange={(event) => {handleSelectChange(event.target.value);}}>
                <Option mappedOptionPairs={mappedOptionPairs} />
            </select>
        </>
    )
}

export default Select;
