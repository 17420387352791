import { getLinks } from "./common";
import { CopyToClipboard } from "react-copy-to-clipboard/lib/Component";
import React from "react";

function Markup({ widgetProps, placementId, getPanelsPerRow, getPanelsRows }) {
  const wProps = { ...widgetProps };
  const ONE_TAB = "   ";
  const TWO_TABS = ONE_TAB + ONE_TAB;

  function getPanelMarkup(panel, startSpacing) {
    const key = panel.internal
      ? "&key=" + wProps.internalKey
      : "&key=" + wProps.uuid;
    const subIds = panel.subIds === "" ? "" : "&sub_ids=" + panel.subIds;
    const links = getLinks(wProps, key, panel, imageSize, subIds);

    return (
      startSpacing +
      "<a href='" +
      links.clickLink +
      "'>\n" +
      startSpacing +
      ONE_TAB +
      "<img src='" +
      links.imgSrc +
      "'/>\n" +
      startSpacing +
      "</a>"
    );
  }

  function getRow(row, currentIndent) {
    let nextIndent = currentIndent + ONE_TAB;
    let rowMarkupString = nextIndent + "<tr>\n";

    row.map((panel) => {
      rowMarkupString += nextIndent + ONE_TAB + "<td>\n";
      rowMarkupString += nextIndent + TWO_TABS + "<table>\n";
      rowMarkupString += nextIndent + TWO_TABS + ONE_TAB + "<tbody>\n";
      rowMarkupString += nextIndent + TWO_TABS + TWO_TABS + "<tr>\n";
      rowMarkupString += nextIndent + TWO_TABS + TWO_TABS + ONE_TAB + "<td>\n";
      rowMarkupString +=
        nextIndent + TWO_TABS + TWO_TABS + TWO_TABS + "<div>\n";

      let startSpacing = nextIndent + TWO_TABS + TWO_TABS + TWO_TABS + ONE_TAB;
      rowMarkupString += getPanelMarkup(panel, startSpacing) + "\n";

      rowMarkupString +=
        nextIndent + TWO_TABS + TWO_TABS + TWO_TABS + "</div>\n";
      rowMarkupString += nextIndent + TWO_TABS + TWO_TABS + ONE_TAB + "</td>\n";
      rowMarkupString += nextIndent + TWO_TABS + TWO_TABS + "</tr>\n";
      rowMarkupString += nextIndent + TWO_TABS + ONE_TAB + "</tbody>\n";
      rowMarkupString += nextIndent + TWO_TABS + "</table>\n";
      rowMarkupString += nextIndent + ONE_TAB + "</td>\n";

      return panel;
    });

    rowMarkupString += nextIndent + "</tr>\n";

    return rowMarkupString;
  }

  function getRows(currentIndent) {
    let panelsMarkupString = "";
    rowList.map((row) => {
      panelsMarkupString += getRow(row, currentIndent);
      return row;
    });
    return panelsMarkupString;
  }

  function getMarkup() {
    let markupString = "";
    let currentIndent = ONE_TAB;

    markupString = "<table>\n" + currentIndent + "<tbody>\n";
    markupString += getRows(currentIndent);
    markupString += currentIndent + "</tbody>\n</table>";

    return markupString;
  }

  // widget
  let key = wProps.uuid;
  const widgetId = wProps.widgetId;
  let placementMarkupString = "";
  let imageSize;
  let rowList;
  let totalPanels;
  if (!widgetId || !key) {
    placementMarkupString = "";
  } else {
    let panels;
    let panelsPerRow;
    wProps.placements.map((placement) => {
      if (placement.id === placementId) {
        imageSize = placement.size;
        panels = placement.panels;
        totalPanels = panels.length;
        panelsPerRow = getPanelsPerRow(placement);
        rowList = getPanelsRows(placementId, panelsPerRow);
      }

      return placement;
    });

    if (totalPanels > 1) {
      placementMarkupString = getMarkup();
    } else {
      placementMarkupString = getPanelMarkup(panels[0], "");
    }
  }

  return (
    <>
      <h4 style={{ textAlign: "center", margin: "15px 0 10px 0" }}>
        GET CODE
        <CopyToClipboard
          text={placementMarkupString}
          onCopy={() => navigator.clipboard.writeText(placementMarkupString)}
        >
          <button
            className="settings-btn"
            style={
              placementMarkupString === ""
                ? { display: "none" }
                : { display: "" }
            }
          >
            Copy
          </button>
        </CopyToClipboard>
      </h4>
      <div id="markup-container">
        <pre id="markup-output">
          {placementMarkupString === ""
            ? "Widget ID needed"
            : placementMarkupString}
        </pre>
      </div>
    </>
  );
}

export default Markup;
