import React, {useState} from "react";
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import Placement from "./widget/Placement";

function PlacementTabs({handleAddPanel, handleDeletePanel, handlePlacementUpdate, resetPlacementLayout, handleDeletePlacement, showDeletePlacementButton, widgetProps, setWidgetProps}) {
    const [selectedPlacement, setSelectedPlacement] = useState(0);

    let placements = widgetProps.placements;

    function deletePlacement(placementId) {
        setSelectedPlacement(0);
        handleDeletePlacement(placementId);
    }

    return(
        <>
            <Tabs selectedIndex={selectedPlacement} onSelect={(placement) => setSelectedPlacement(placement)}>
                <TabList>
                    {
                        placements.map(placement => (
                            <Tab key={placement.id}>Placement {placement.id}</Tab>
                        ))
                    }
                </TabList>
                {
                    placements.map(placement => (
                        <TabPanel key={placement.id}>
                            <Placement placementComponent={placement} handleAddPanel={handleAddPanel} handleDeletePanel={handleDeletePanel}
                                       handlePlacementUpdate={handlePlacementUpdate} showDeletePlacementButton={showDeletePlacementButton}
                                       deletePlacement={deletePlacement} resetPlacementLayout={resetPlacementLayout}
                                       widgetProps={widgetProps} setWidgetProps={setWidgetProps} />
                        </TabPanel>
                    ))
                }
            </Tabs>
        </>
    )
}

export default PlacementTabs;
